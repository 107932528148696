<template>
    <div class="addFile">
        <el-upload
            class="upload-demo"
            :action="action"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleAvatarSuccess"
            :file-list="urlList"
            :headers="headers"
            :list-type="type"
            :disabled="disabled"
            :before-upload="beforeAvatarUpload"
            accept=".wps,.wpt,.doc,.dot,.docx,.dotx,.docm,.dotm,.xls,.xlsx,.xlsm,.xltm,.xlsb,.ppt,.pptx,.pdf,.jpg,.png,.jpeg,.PDF,.pdf"
        >
            <el-button type="primary" :icon="Paperclip" readonly :disabled="disabled" plain>点击上传</el-button>
            <!-- <el-input readonly :disabled="disabled" class="upload-input" placeholder="点击添加"></el-input> -->
        </el-upload>
    </div>
</template>
<script setup>
import { ref, reactive, computed, watch, onMounted, onUnmounted, toRefs } from 'vue';
import { Paperclip } from '@element-plus/icons-vue';
import axios from 'axios';
import $store from '@/store';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus/lib/components';
const router = useRouter();
const emit = defineEmits(['close', 'putUrl']);
let headers = {
    Authorization: localStorage.token,
};
const props = defineProps({
    // 要回显的附件url数组
    fileList: {
        type: String,
    },
    //   是否禁用
    disabled: {
        type: Boolean,
    },
    //   文件类型
    type: {
        type: String,
    },
});
const { fileList } = toRefs(props);
let action = process.env.VUE_APP_API_FILE_URL;
const test = computed(() => {
    return null;
});
let urlList = ref([]);
watch(() => {});
onMounted(() => {
    // 区分内网和互联网环境的上传,内网不能用oss
    if (process.env.VUE_APP_API_ENV_IRONMENT === 'intranet') {
        setTimeout(() => {
            console.log(fileList.value);
            // 处理数据为组件要求的格式{name:'xzxx',url:'xxx'}
            if (fileList.value.length) {
                urlList.value = fileList.value.map((item) => {
                    item = {
                        name: item.name,
                        url: item.url,
                    };
                    return item;
                });
            }
            console.log('fileList', urlList.value);
        }, 600);
    } else {
        // action.value = process.env.VUE_APP_API_BASE_URL + '/api/oss/upload';
        setTimeout(() => {
            console.log('fileList.value', fileList.value);
            if (fileList.value.length) {
                urlList.value = fileList.value.map((item) => {
                    item = {
                        attachmentSize: item.fileSize,
                        attachmentKey: item.originalKey,
                        attachmentName: item.originalName,
                        name: item.originalName,
                        url: process.env.VUE_APP_API_IMG_BASEURL + `/api/oss/getFileByKey?key=${item.originalKey}`,
                    };
                    return item;
                });
                emit('putUrl', urlList.value);
            }
            console.log('fileList', urlList.value);
        }, 600);
    }
    console.log('action', action);
});
onUnmounted(() => {});

async function handlePreview(file) {
    if (file.url) {
        window.open(file.url);
    } else {
        window.open(
            process.env.VUE_APP_API_IMG_BASEURL + `/api/oss/getFileByKey?key=${file.response.msg.split('|')[0]}`
        );
    }
    console.log(file);
}

let fileUrl = '';
function handleAvatarSuccess(file) {
    let emitUrl = [];
    if (process.env.VUE_APP_API_ENV_IRONMENT === 'intranet') {
        console.log(file, urlList.value);
        urlList.value.push({
            name: file.data.fileName,
            url: file.data.url,
        });
        urlList.value = urlList.value.filter((item) => item.url);
        fileUrl = urlList.value.map((item) => item.url).join(',');
        console.log('fileUrl', fileUrl, 'urlList', urlList.value);
        emit('putUrl', urlList.value);
    } else {
        emitUrl = urlList.value.map((item) => {
            if (item.response) {
                return {
                    name: item.name,
                    attachmentName: `${item.response.msg.split('|')[1]}.${item.response.msg.split('|')[2]}`,
                    attachmentKey: item.response.msg.split('|')[0],
                    attachmentSize: item.response.msg.split('|')[3],
                };
            } else {
                return item;
            }
        });
        emit('putUrl', emitUrl);
    }
}
// 移除选中的图片并且返回
function handleRemove(file) {
    let emitUrl = '';
    // 区分内网和互联网环境的上传,内网不能用oss
    if (process.env.VUE_APP_API_ENV_IRONMENT === 'intranet') {
        urlList.value = urlList.value.filter((item) => item.url !== file.url);
    } else {
        urlList.value = urlList.value.filter((item) => item.uid !== file.uid);
        emitUrl = urlList.value.map((item) => {
            if (item.response) {
                return {
                    name: item.name,
                    attachmentName: `${item.response.msg.split('|')[1]}.${item.response.msg.split('|')[2]}`,
                    attachmentKey: item.response.msg.split('|')[0],
                    attachmentSize: item.response.msg.split('|')[3],
                };
            } else {
                return item;
            }
        });
    }
    fileUrl = urlList.value.map((item) => item.url).join(',');
    console.log(' urlList.value', emitUrl);
    emit('putUrl', emitUrl);
}
function beforeAvatarUpload(file) {
    console.log(file);
    console.log(file.name.split('.')[file.name.split('.').length - 1]);

    let str =
        '.wps,.wpt,.doc,.dot,.docx,.dotx,.docm,.dotm,.xls,.xlsx,.xlsm,.xltm,.xlsb,.ppt,.pptx,.pdf,.jpg,.png,.jpeg,.PDF,.pdf';
    if (file.size > 104857600) {
        ElMessage.error('超过限制100M大小!');
        return false;
    }
    if (str.indexOf(file.name.split('.')[file.name.split('.').length - 1]) < 0) {
        ElMessage.error('选择文件类型不匹配');
        return false;
    }
}
</script>
<style lang="less" scoped>
.addFile {
    .upload-demo {
        /deep/ .el-input__inner {
            width: 400px;
        }
    }
    /deep/ .el-upload-list__item-file-name {
        width: 320px;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
    }
}
</style>
