<template>
    <div class="auditRaecords">
        <div v-if="type == 1 || type == 4">
            <el-table
                :data="tableData"
                :default-sort="{ prop: 'createTime', order: 'descending' }"
                style="width: 100%; height: 100%"
                header-row-class-name="table-header"
                :header-cell-style="{
                    background: '#F7F8FA',
                    color: '#333333',
                    height: '54px',
                    'font-size': '14px',
                }"
            >
                <el-table-column prop="verifyNodeName" label="审核环节"></el-table-column>
                <el-table-column prop="verifyPersonName" label="审核人"></el-table-column>
                <el-table-column prop="remark" label="审核意见"> </el-table-column>
                <el-table-column prop="verifyTime" label="审核时间"> </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    v-model:currentPage="query.page"
                    v-model:page-size="query.size"
                    :page-sizes="[15, 30, 50, 100]"
                    background
                    layout="total, prev, pager, next, jumper"
                    :total="total"
                    @size-change="getList"
                    @current-change="getList"
                >
                </el-pagination>
            </div>
        </div>
        <div v-if="type == 2">
            <el-table
                :data="tableData"
                :default-sort="{ prop: 'createTime', order: 'descending' }"
                style="width: 100%; height: 100%"
                header-row-class-name="table-header"
                :header-cell-style="{
                    background: '#F7F8FA',
                    color: '#333333',
                    height: '54px',
                    'font-size': '14px',
                }"
            >
                <el-table-column prop="indexName" label="指标名称"></el-table-column>
                <el-table-column prop="sourceName" label="数源应用名称"></el-table-column>
                <el-table-column prop="orgName" label="是否涉密" class-name="textCenter"></el-table-column>
                <el-table-column prop="orgName" label="责任单位"></el-table-column>
                <el-table-column
                    prop="orgNum"
                    label="责任单位目标值"
                    width="130"
                    class-name="textCenter"
                ></el-table-column>
                <el-table-column prop="completeNum" label="完成值" class-name="textCenter"></el-table-column>
                <el-table-column prop="status" label="状态" class-name="textCenter">
                    <template #default="{ row }">
                        <div>
                            <span :class="['status', 'status' + row.status]" v-if="row.status == '0'" slot="reference"
                                >• 草稿</span
                            >
                            <span :class="['status', 'status' + row.status]" v-if="row.status == '1'" slot="reference"
                                >• 审核中</span
                            >
                            <span :class="['status', 'status' + row.status]" v-if="row.status == '2'" slot="reference"
                                >• 执行中</span
                            >
                            <span :class="['status', 'status' + row.status]" v-if="row.status == '3'" slot="reference"
                                >• 已完成</span
                            >
                            <span :class="['status', 'status' + row.status]" v-if="row.status == '4'" slot="reference"
                                >• 审核驳回</span
                            >
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    v-model:currentPage="query.page"
                    v-model:page-size="query.size"
                    :page-sizes="[15, 30, 50, 100]"
                    background
                    layout="total, prev, pager, next, jumper"
                    :total="total"
                    @size-change="getList"
                    @current-change="getList"
                >
                </el-pagination>
            </div>
        </div>
        <div v-if="type == 3">
            <el-table
                :data="tableData"
                :default-sort="{ prop: 'createTime', order: 'descending' }"
                style="width: 100%; height: 100%"
                header-row-class-name="table-header"
                :header-cell-style="{
                    background: '#F7F8FA',
                    color: '#333333',
                    height: '54px',
                    'font-size': '14px',
                }"
            >
                <el-table-column prop="personName" label="填报人"></el-table-column>
                <el-table-column prop="completeNum" label="完成值"></el-table-column>
                <el-table-column prop="writeTime" label="填报时间"> </el-table-column>
                <el-table-column prop="fileName" label="相关附件">
                    <template #default="scope">
                        <div v-for="(item, index) in scope.row.filePath" :key="index">
                            <!-- <a :href="item.url" target="_blank" v-if="item.url" class="hrefs">
                                {{ item.originalName }}
                            </a> -->
                            <span v-if="item.url" class="hrefs" @click="openFilePath(item)">
                                {{ item.originalName }}</span
                            >
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, defineEmits, defineProps, getCurrentInstance } from 'vue';
let tableData = ref([]);
const { proxy } = getCurrentInstance();
let total = ref(0);
let query = {
    size: 5,
    page: 1,
    actionId: '',
    indexId: '',
};
let emit = defineEmits();
let props = defineProps(['id', 'indexId', 'type']);
// type 1为指标查看2为重大任务查看3为任务执行查看
const { id, indexId, type } = props;
console.log(id, indexId, type);
if (type == 2) {
    query.statuses = '1,2,3,4';
}
if (type == 1) {
    query.indexId = indexId;
} else {
    query.actionId = id;
}
getList();
function getList() {
    if (type == 3) {
        proxy.$api.frontIndexUnit.getById(id).then((res) => {
            if (res.code == 200) {
                tableData.value = res.data.frontIndexUnitLogList;
                // 区分内网和互联网环境的上传,内网不能用oss
                if (process.env.VUE_APP_API_ENV_IRONMENT === 'intranet') {
                    tableData.value.map((item) => {
                        item.filePath.forEach((e) => {
                            e.originalName = e.name;
                        });
                    });
                } else {
                    tableData.value.map((item) => {
                        item.filePath.forEach((e) => {
                            e.url = process.env.VUE_APP_API_IMG_BASEURL + `/api/oss/getFileByKey?key=${e.originalKey}`;
                        });
                    });
                }
            }
        });
    } else if (type == 2 || type == 5) {
        proxy.$api.frontIndexUnit.getPage(query).then((res) => {
            if (res.code == 200) {
                tableData.value = res.data.records;
                total.value = res.data.total;
                console.log(tableData.value);
            }
        });
    } else {
        proxy.$api.frontVerify.getPage(query).then((res) => {
            if (res.code == 200) {
                tableData.value = res.data.records;
                total.value = res.data.total;
                console.log(res.data.records, tableData.value);
            }
        });
    }
    console.log(tableData.value);
}
function openFilePath(row) {
    fetch(`${row.url}`, {
        headers: {
            Authorization: sessionStorage.token,
        },
    }).then((res) => {
        res.blob().then((blob) => {
            var a = document.createElement('a');
            var url = window.URL.createObjectURL(blob);
            var filename = row.originalName;
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
        });
    });
}
onMounted(() => {});
</script>

<style scoped lang="stylus">
.auditRaecords
    width: 100%
    margin-bottom: 16px
    /deep/ .el-table
        min-height 120px
    // /deep/ .el-table__body
    //     min-height 34px
    .pagination
        text-align: right;
    .status
        display: inline-block
        width: 68px;
        height: 24px;
        text-align: center
        line-height: 24px
        &.status2
            background: rgba(100, 183, 255, 0.2);
            color: #1492FF;

        &.status3
            background: rgba(68, 203, 87, 0.2);
            color: #44CD40;

        &.status1
            background: rgba(255, 176, 81, 0.2);
            color: #FF8B00;

        &.status4
            background: rgba(255, 127, 127, 0.2);
            color: #FF5C5C;

        &.status0
            background: rgba(216, 216, 216, 0.2);
            color: #999999;
    .hrefs
        font-size: 14px;
        color: #1492FF;
        text-decoration none
/deep/ .el-pagination
    justify-content: flex-end
    margin-top 18px
</style>
